import React from "react";
import ConfigurationPDF from "./ConfigurationPDF"
import ResultsPDF from "./ResultsPDF"

import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";
import logo from "../img/logo_full.png"


const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column'
    },
    logo: {
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    titleContainer: {
        flexDirection: 'row',
        marginTop: 24,
    },
    reportTitle: {
        color: '#000000',
        letterSpacing: 4,
        fontSize: 25,
        textAlign: 'center',
    }

});

const Title = ({title}) => {
        return (
            <View style={styles.titleContainer}>
                <Text style={styles.reportTitle}>{title.title}</Text>
            </View>
        )
    }
;


const PdfDocument = (props) => {

    const configurationData = {
        plant: props.data.plant,
        flow: props.data.flow,
        optionals: props.data.optionals,
        ref: props.title.title
    };

    const utilitiesData = {
        total_installed_power: props.data.total_installed_power,
        total_absorbed_power: props.data.total_absorbed_power,
        makeup_water: props.data.makeup_water,
        compressed_air: props.data.compressed_air
    };

    return (
        <Document>
            <Page key={"page-0"} style={styles.page}>
                <Image style={styles.logo} src={logo}/>
                <Title title={props.title}/>
                <ConfigurationPDF configuration={configurationData}/>
                <ResultsPDF results={utilitiesData}/>
            </Page>
        </Document>
    );
};

export default PdfDocument;