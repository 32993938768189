import React, {Component} from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import {withAuth0} from '@auth0/auth0-react'
import Col from "react-bootstrap/Col";
import Jumbotron from "react-bootstrap/Jumbotron";
import Notification from "./Notification";
import {Row} from "react-bootstrap";
import {form} from "./data/form.js"
import CalculatorComponent from "./CalculatorComponent";
import Table from "react-bootstrap/Table";
import DownloadModal from "./PDF/DownloadModalComponent";

class UtilitiesContainer extends Component {

    state = {
        flow: 0,
        plant: "",
        optionals: [0, 0, 0, 0],
        total_installed_power: 0,
        total_absorbed_power: 0,
        makeup_water: 0,
        compressed_air: 0,
        is_calculation_finished: false,
        notifications: []
    };

    handleCalculation = (values) => {
        let s = this.state;

        s.flow = values.flow;
        s.plant = values.plant;
        s.optionals = values.optionals;


        this.setState(s);

        s.total_installed_power = this.calculate_installed_power();
        s.total_absorbed_power = this.calculate_absorbed_power(s.total_installed_power);
        s.makeup_water = this.calculate_makeup_water();
        s.compressed_air = this.calculate_compressed_air();
        s.is_calculation_finished = true;

        this.setState(s);
    };

    calculate_installed_power = () => {
        let s = this.state;

        let formula = form["INSTALLED_POWER"]["formulas"][s.plant]["formula"];

        formula = formula.replaceAll("Q", s.flow);

        const partial_installed_power = eval(formula);
        let optionals_installed_power = 0;

        Object.keys(s.optionals).forEach((o) => {
            if (s.optionals[o] === true || s.optionals === 1) {
                let formula = form["INSTALLED_POWER"]["formulas"][s.plant]["optionals"][o];
                if (o === "UVC") {
                    let absorbed_power = this.get_absorbed_power_UVC();

                    formula = formula.replaceAll("ABSORBED_POWER", absorbed_power)
                } else if (o === "OZONE") {
                    formula = formula.replaceAll("Q", s.flow)
                }

                optionals_installed_power += eval(formula);

            }
        });


        return Math.ceil((partial_installed_power + optionals_installed_power) * 10) / 10;

    };

    get_absorbed_power_UVC = () => {
        let ap = "";
        Object.keys(form["ABSORBED_POWER"]["values"]).forEach((key) => {
            if (this.state.flow >= Number(key)) {
                ap = form["ABSORBED_POWER"]["values"][key];
            }
        });

        return ap
    };

    calculate_absorbed_power = (total_installed_power) => {
        let formula = form["TOTAL_ABSORBED_POWER"]["formula"].replaceAll("INSTALLED_POWER", total_installed_power);

        return Math.ceil(eval(formula) * 10) / 10;
    };

    calculate_makeup_water = () => {
        let s = this.state;
        let formula;
        if (s.optionals["ZD"])
            formula = "0";
        else if (s.optionals["BWR"])
            formula = form["MAKE_UP_WATER"]["formulas"][s.plant]["optionals"]["BWR"].replaceAll("Q", s.flow)
        else
            formula = form["MAKE_UP_WATER"]["formulas"][s.plant]["formula"].replaceAll("Q", s.flow)


        return Math.ceil(eval(formula) * 10) / 10;
    };

    calculate_compressed_air = () => {

        let formula = (form["COMPRESSED_AIR"]["formulas"][this.state.plant].replaceAll("Q", this.state.flow));

        return Math.ceil(eval(formula) * 10) / 10;

    };


    render() {
        if (this.props.auth0.isAuthenticated)
            if (this.state.isLoading) {
                return (
                    <Jumbotron fluid>
                        <Container className="justify-content-md-center">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </Container>
                    </Jumbotron>
                )
            } else
                return (
                    <React.Fragment>
                        <div className="jumbotron jumbotron-fluid">
                            <Container className="justify-content-md-center">
                                <Row>
                                    <Col>
                                        <h1>Utilities Calculator</h1>
                                    </Col>
                                    <Col md={{span: 4, offset: 4}}>
                                        {
                                            this.state.notifications.length > 0 && this.state.notifications.map(n =>
                                                <Notification key={`Notification-${n.id}`}
                                                              data={n}/>)
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <Container>
                            <CalculatorComponent onCalculate={this.handleCalculation}/>
                        </Container>
                        {this.state.is_calculation_finished && <Container>
                            <Row>
                                <Col>
                                    <h1>Results</h1>
                                </Col>
                            </Row>
                            <Row>
                                <Table bordered hover>
                                    <thead>
                                    <tr>
                                        <th>Utility</th>
                                        <th>Result</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td><strong>Total installed power</strong></td>
                                        <td>{this.state.total_installed_power} {form["INSTALLED_POWER"]["UOM"]}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Total absorbed power</strong></td>
                                        <td>{this.state.total_absorbed_power} {form["TOTAL_ABSORBED_POWER"]["UOM"]}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Make-up water</strong></td>
                                        <td>{this.state.makeup_water} {form["MAKE_UP_WATER"]["UOM"]}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Compressed air</strong></td>
                                        <td>{this.state.compressed_air} {form["COMPRESSED_AIR"]["UOM"]}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Row>
                            <Row className="d-flex flex-row-reverse">
                                <DownloadModal configuration={this.state}>
                                </DownloadModal>
                            </Row>
                        </Container>
                        }
                    </React.Fragment>
                );
        else return (<div/>)
    }
}

export default withAuth0(UtilitiesContainer);

