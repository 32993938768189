import React from 'react';
import './App.css';
import logo from "./img/logo_small.png"
import {NavLink} from 'react-router-dom'
import {useAuth0} from '@auth0/auth0-react'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';

function NavComponent() {
    const NavStyle = {
        textDecoration: 'none'
    };
    const imageStyle = {
        width: "10em"
    };

    const {isAuthenticated, loginWithRedirect, logout} = useAuth0();

    return <Navbar variant={"dark"} expand="lg" className={"nav-bar-min-height color-nav"}>
        <Navbar.Brand><Image style={imageStyle} src={logo}/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto ">
                {
                    isAuthenticated && <React.Fragment>
                        <NavLink
                            className="nav-link"
                            to="/"
                            style={NavStyle}
                            isActive={() => {
                                return !((window.location.pathname).includes("/utilities"))
                            }}
                        >
                            Home <span className="sr-only">(current)</span>
                        </NavLink>
                        <NavLink
                            className="nav-link"
                            to="/utilities"
                            style={NavStyle}
                            isActive={() => {
                                return ((window.location.pathname).includes("/utilities"))
                            }}
                        >
                            Utilities <span className="sr-only">(current)</span>
                        </NavLink>
                    </React.Fragment>
                }
            </Nav>
            <Form inline>
                {
                    isAuthenticated ?
                        <button className="btn btn-lg btn-primary btn-block" onClick={() => logout({returnTo: "https://santiflli-manager.com"})}>Esci</button> :
                        <button className="btn btn-lg btn-primary btn-block"
                                onClick={() => loginWithRedirect()}>Accedi</button>
                }
            </Form>
        </Navbar.Collapse>
    </Navbar>
}

export default NavComponent;
