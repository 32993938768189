import React, {useState} from 'react';
import './App.css';
import Toast from 'react-bootstrap/Toast';


const Notification = ({data}) => {

    const [show, setShow] = useState(data.show);

    return (
        <div
            aria-live="polite"
            aria-atomic="true"
            style={{
                position: 'fixed',
            }}
        >
            <div
                style={{
                    position: 'fixed',
                    top: "5em",
                    right: "5em",
                    background: data.variant === "success" ? "green" : 'red'
                }}
            >
                <Toast
                    onClose={() => setShow(false)}
                    show={show}
                    delay={5000}
                    autohide>
                    <Toast.Header>
                        <img
                            src="img/logo_small.png"
                            className="rounded mr-2"
                            alt=""
                        />
                        <strong className="mr-auto">{data.message}</strong>
                    </Toast.Header>
                    {
                        data.error !== null && <Toast.Body>{data.error}</Toast.Body>
                    }
                </Toast>
            </div>
        </div>
    );

}


export default Notification;
