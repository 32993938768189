import React, {Component} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useFormik} from 'formik';
import 'bootstrap/dist/css/bootstrap.min.css';
import {form} from "./data/form.js"


const isValidFlow = (value) => {
    const Q = parseFloat(value);
    return Q >= 10 && Q <= 365;
};

// A custom validation function. This must return an object
// which keys are symmetrical to our values/initialValues
const validate = values => {
    const errors = {};
    if (!values.flow) {
        errors.flow = 'Flow rate is a mandatory field!';
    } else if (!isValidFlow(values.flow)) {
        errors.flow = "Flow rate must be in the range 10-365!"
    }

    return errors;
};

const CalculatorComponent = ({onCalculate}) => {
    // Pass the useFormik() hook initial form values and a submit function that will
    // be called when the form is submitted

    const formik = useFormik({
        initialValues: {
            plant: "IDRO 1",
            flow: 0,
            optionals: {
                BWR: -1,
                ZD: -1,
                UVC: -1,
                OZONE: -1
            }
        },
        validate,
    });

    const handlePlantSelection = (value) => {

        let optionals = form["INSTALLED_POWER"]["formulas"][value]["optionals"];
        let optionals_value = formik.values.optionals;

        optionals["BWR"] === "not available" ? optionals_value["BWR"] = -1 : optionals_value["BWR"] = 0;
        optionals["ZD"] === "not available" ? optionals_value["ZD"] = -1 : optionals_value["ZD"] = 0;
        optionals["UVC"] === "not available" ? optionals_value["UVC"] = -1 : optionals_value["UVC"] = 0;
        optionals["OZONE"] === "not available" ? optionals_value["OZONE"] = -1 : optionals_value["OZONE"] = 0;

        formik.setFieldValue("plant", value);
        formik.setFieldValue("optionals", optionals_value);

    };

    return (
        <React.Fragment>
            <Form onSubmit={(event) => {
                event.preventDefault();
                onCalculate(formik.values);
            }}>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                        Base Configuration
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            as="select"
                            id={"plants"}
                            onChange={(event) => handlePlantSelection(event.target.value)}
                        >
                            <option value="IDRO 1">IDRO 1 {form["INSTALLED_POWER"]["formulas"]["IDRO 1"]["base_specification"]}</option>
                            <option value="IDRO 2">IDRO 2 {form["INSTALLED_POWER"]["formulas"]["IDRO 2"]["base_specification"]}</option>
                            <option value="IDRO 3">IDRO 3 {form["INSTALLED_POWER"]["formulas"]["IDRO 3"]["base_specification"]}</option>
                            <option value="IDRO 4">IDRO 4 {form["INSTALLED_POWER"]["formulas"]["IDRO 4"]["base_specification"]}</option>
                            <option value="IDRO 5">IDRO 5 {form["INSTALLED_POWER"]["formulas"]["IDRO 5"]["base_specification"]}</option>
                            <option value="IDRO 6">IDRO 6 {form["INSTALLED_POWER"]["formulas"]["IDRO 6"]["base_specification"]}</option>
                        </Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                        Flow rate (m³/h)
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text"
                                      id={"flow"}
                                      name={"flow"}
                                      placeholder="Input the flow rate value"
                                      onChange={formik.handleChange}
                                      value={formik.values.flow === 0 ? '' : formik.values.flow}
                                      autoComplete={"off"}
                        />
                    </Col>
                </Form.Group>
                <fieldset>
                    <Form.Group as={Row}>
                        <Form.Label as="legend" column sm={2}>
                            Optionals
                        </Form.Label>
                        <Radio id={"optionals"}
                               onChange={(value) => formik.setFieldValue("optionals", value)}
                               status={formik.values.optionals}
                        />
                    </Form.Group>
                </fieldset>
                <div className="d-flex flex-row-reverse">
                    <Button variant="primary"
                            onClick={() => {
                                onCalculate(formik.values);
                            }}
                            disabled={(formik.errors.plant || formik.errors.flow || formik.values.flow === 0)}
                    >
                        Calculate
                    </Button>
                </div>
                <Form.Group as={Row}>
                    <Col sm={{span:10, offset:2}}>
                        {formik.errors.flow ? <div style={{color:"red"}}>{formik.errors.flow}</div> : null}
                    </Col>
                </Form.Group>
            </Form>
        </React.Fragment>
    );
};


class Radio extends Component {
    constructor(props) {
        super(props);
        this.checks = ["BWR", "ZD", "UVC", "OZONE"]
    }

    handleChange(key, event) {
        let s = this.props.status;
        if (key === "BWR") {
            s.BWR = event.target.checked;
        }
        if (key === "ZD") {
            s.ZD = event.target.checked;
        }
        if (key === "UVC") {
            s.UVC = event.target.checked;
        }
        if (key === "OZONE") {
            s.OZONE = event.target.checked;
        }

        this.props.onChange(s)
    }

    // Renders all of the check-boxes
    renderChecks() {
        return this.checks.map(
            k =>
                <Col key={`COL-${k}`}>
                    <Form.Check
                        type='checkbox'
                        checked={this.props.status[k] === -1 ? 0 : this.props.status[k]}
                        label={this.props.status[k] === -1 ? `${k} (Not Available)` : k}
                        onChange={this.handleChange.bind(this, k)}
                        key={k}
                        disabled={this.props.status[k] === -1}
                    />
                </Col>);
    }

    render() {
        return (
            this.renderChecks()
        );
    }
}


export default CalculatorComponent;