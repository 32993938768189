import React from 'react';
import './App.css';
import HomeContainer from './Home.js';
import NavComponent from './NavComponent'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Footer from "./Footer";
import {withAuth0} from '@auth0/auth0-react'
import UtilitiesContainer from "./UtilitiesContainer";

require("dotenv").config()

class App extends React.Component {

  render() {

    return (
        <Router>
          <NavComponent/>
          <Switch>
              <Route path="/" exact component={HomeContainer}/>
              <Route path="/utilities" exact component={UtilitiesContainer}/>
          </Switch>
          <Footer/>
        </Router>
    );

  }
}

export default withAuth0(App);
