import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
import App from './App';
import {Auth0Provider} from '@auth0/auth0-react';
import 'bootstrap/dist/css/bootstrap.min.css';

require("dotenv").config()

const Auth0ProviderWithHistory = ({children}) => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

    return <Auth0Provider
        domain={domain}
        clientId={clientId}
        redirectUri={window.location.origin}
    >
        {children}
    </Auth0Provider>
}

ReactDOM.render(
    <Router>
        <Auth0ProviderWithHistory>
            <App/>
        </Auth0ProviderWithHistory>
    </Router>,
    document.getElementById('root')
);
