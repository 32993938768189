import React from 'react';
import './App.css';
import {withAuth0} from '@auth0/auth0-react'
import logo from "./img/logo_full.png"
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


const HomeContainer = () => {
    return (
    <>
        <Row className="justify-content-md-center">
            <Col xs={12} sm={4} md={4}>
                <Image className={"home-image"} src={logo} fluid/>
            </Col>
        </Row>
        <Row className="justify-content-md-center">
            <h1>Utilities calculator tool</h1>
        </Row>
       </>
    );
}


export default withAuth0(HomeContainer);
