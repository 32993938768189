export const form = {
  "INSTALLED_POWER": {
    "UOM": "kW",
    "formulas": {
      "IDRO 1": {
        "formula": "9.81 * Q / 3.6 * 25 * 1.2 / 700 + 1.75",
        "optionals": {
          "BWR": "not available",
          "ZD": "not available",
          "UVC": "not available",
          "OZONE": "not available"
        },
        "base_specification": "(BF + 2 x BGF)"
      },
      "IDRO 2": {
        "formula": "(9.81 * Q / 3.6 * 25 * 1.2 / 700) + (9.81 * 0.4 * Q / 3.6 * 35 * 1.2 / 700 + 3.75)",
        "optionals": {
          "BWR": "not available",
          "ZD": "not available",
          "UVC": "not available",
          "OZONE": "not available"
        },
        "base_specification": "( (BAS) + BAF + 2 x BGF)"
      },
      "IDRO 3": {
        "formula": "((9.81 * 0.4 * Q / 3.6 * 35 * 1.2) / 700) + ((9.81 * Q / 3.6 * 35 * 1.2) / 700) + 8.92",
        "optionals": {
          "BWR": "5.5",
          "ZD": "5.5",
          "UVC": "1.2 * ABSORBED_POWER",
          "OZONE": "126 * Q / 1000"
        },
        "base_specification": "( (BAS) + BAF + SF + BGF)"
      },
      "IDRO 4": {
        "formula": "(9.81 * Q / 3.6 * 35 * 1.2 / 700) + (9.81 * 0.15 * Q / 3.6 * 55 * 1.2 / 700) + 5.92",
        "optionals": {
          "BWR": "10",
          "ZD": "5.5",
          "UVC": "1.2 * ABSORBED_POWER",
          "OZONE": "126 * Q / 1000"
        },
        "base_specification": "(BAS + SFF + BGF)"
      },
      "IDRO 5": {
        "formula": "(9.81 * Q / 3.6 * 35 * 1.2 / 700) + (9.81 * 0.4 * Q / 3.6 * 35 * 1.2 / 700) + (9.81 * 0.15 * Q / 3.6 * 55 * 1.2 / 700) + 20.42",
        "optionals": {
          "BWR": "5.5",
          "ZD": "5.5",
          "UVC": "1.2 * ABSORBED_POWER",
          "OZONE": "126 * Q / 1000"
        },
        "base_specification": "(DAF / BAF + SFF + BGF)"
      },
      "IDRO 6": {
        "formula": "(9.81 * Q / 3.6 * 35 * 1.2 / 700) + (9.81 * 0.4 * Q / 3.6 * 35 * 1.2 / 700) + (9.81 * 0.15 * Q / 3.6 * 55 * 1.2 / 700) + (9.81 * Q / 3.6 * 25 * 1.2 / 700) + 23.92",
        "optionals": {
          "BWR": "5.5",
          "ZD": "5.5",
          "UVC": "1.2 * ABSORBED_POWER",
          "OZONE": "126 * Q / 1000"
        },
        "base_specification": "(PF + SCR + DAF / BAF + SFF + BGF)"
      }
    }
  },
  "ABSORBED_POWER": {
    "UOM": "kW",
    "values": {
      "10": "O.34",
      "13": "O.6",
      "25": "O.76",
      "41": "1.1",
      "61": "1.72",
      "108": "2.38",
      "145": "3.7",
      "241": "5.7"
    }
  },
  "TOTAL_ABSORBED_POWER": {
    "UOM": "kW",
    "formula": "INSTALLED_POWER * 0.8"
  },
  "MAKE_UP_WATER": {
    "UOM": "m³/h",
    "formulas": {
      "IDRO 1": {
        "formula": "0",
        "optionals": {
          "BWR": "not available",
          "ZD": "not available"
        }
      },
      "IDRO 2": {
        "formula": "Q * 0.03",
        "optionals": {
          "BWR": "not available",
          "ZD": "not available"
        }
      },
      "IDRO 3": {
        "formula": "Q * 0.07",
        "optionals": {
          "BWR": "Q * 0.03",
          "ZD": "0"
        }
      },
      "IDRO 4": {
        "formula": "Q * 0.04",
        "optionals": {
          "BWR": "Q * 0.03",
          "ZD": "0"
        }
      },
      "IDRO 5": {
        "formula": "Q * 0.07",
        "optionals": {
          "BWR": "Q * 0.03",
          "ZD": "0"
        }
      },
      "IDRO 6": {
        "formula": "Q * 0.07",
        "optionals": {
          "BWR": "Q * 0.03",
          "ZD": "0"
        }
      }
    }
  },
  "COMPRESSED_AIR": {
    "UOM": "Nm³/h",
    "formulas": {
      "IDRO 1": "5",
      "IDRO 2": "5 + Q * 0.02",
      "IDRO 3": "5 + Q * 0.02",
      "IDRO 4": "5 + Q * 0.02",
      "IDRO 5": "5 + Q * 0.025",
      "IDRO 6": "5 + Q * 0.055"
    }
  }
}

