import React from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';


function Footer() {
    return (
        <footer className="footer mt-auto py-3">
            <Container>
                <span>Idrosistem Energy s.r.l., all rights reserved ©</span>
            </Container>
        </footer>
    );
}

export default Footer;
